/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      description
      assignedBy
      dueDate
      status
      userID
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      description
      assignedBy
      dueDate
      status
      userID
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      description
      assignedBy
      dueDate
      status
      userID
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVoteRecord = /* GraphQL */ `
  mutation CreateVoteRecord(
    $input: CreateVoteRecordInput!
    $condition: ModelVoteRecordConditionInput
  ) {
    createVoteRecord(input: $input, condition: $condition) {
      id
      voterID
      votingEventID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVoteRecord = /* GraphQL */ `
  mutation UpdateVoteRecord(
    $input: UpdateVoteRecordInput!
    $condition: ModelVoteRecordConditionInput
  ) {
    updateVoteRecord(input: $input, condition: $condition) {
      id
      voterID
      votingEventID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVoteRecord = /* GraphQL */ `
  mutation DeleteVoteRecord(
    $input: DeleteVoteRecordInput!
    $condition: ModelVoteRecordConditionInput
  ) {
    deleteVoteRecord(input: $input, condition: $condition) {
      id
      voterID
      votingEventID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      country
      paymentHistory {
        nextToken
        __typename
      }
      city
      spokenLanguages
      profession
      membershipStatus
      votes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      country
      paymentHistory {
        nextToken
        __typename
      }
      city
      spokenLanguages
      profession
      membershipStatus
      votes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      country
      paymentHistory {
        nextToken
        __typename
      }
      city
      spokenLanguages
      profession
      membershipStatus
      votes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      amountInUSD
      paymentType
      paymentMethod
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentDate
      memo
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      amountInUSD
      paymentType
      paymentMethod
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentDate
      memo
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      amountInUSD
      paymentType
      paymentMethod
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentDate
      memo
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      date
      zoomLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      date
      zoomLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      date
      zoomLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVotingEvent = /* GraphQL */ `
  mutation CreateVotingEvent(
    $input: CreateVotingEventInput!
    $condition: ModelVotingEventConditionInput
  ) {
    createVotingEvent(input: $input, condition: $condition) {
      id
      topic
      description
      deadline
      hideResults
      options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVotingEvent = /* GraphQL */ `
  mutation UpdateVotingEvent(
    $input: UpdateVotingEventInput!
    $condition: ModelVotingEventConditionInput
  ) {
    updateVotingEvent(input: $input, condition: $condition) {
      id
      topic
      description
      deadline
      hideResults
      options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVotingEvent = /* GraphQL */ `
  mutation DeleteVotingEvent(
    $input: DeleteVotingEventInput!
    $condition: ModelVotingEventConditionInput
  ) {
    deleteVotingEvent(input: $input, condition: $condition) {
      id
      topic
      description
      deadline
      hideResults
      options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVotingOption = /* GraphQL */ `
  mutation CreateVotingOption(
    $input: CreateVotingOptionInput!
    $condition: ModelVotingOptionConditionInput
  ) {
    createVotingOption(input: $input, condition: $condition) {
      id
      type
      value
      name
      email
      votingEventID
      votingEvent {
        id
        topic
        description
        deadline
        hideResults
        createdAt
        updatedAt
        __typename
      }
      votes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVotingOption = /* GraphQL */ `
  mutation UpdateVotingOption(
    $input: UpdateVotingOptionInput!
    $condition: ModelVotingOptionConditionInput
  ) {
    updateVotingOption(input: $input, condition: $condition) {
      id
      type
      value
      name
      email
      votingEventID
      votingEvent {
        id
        topic
        description
        deadline
        hideResults
        createdAt
        updatedAt
        __typename
      }
      votes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVotingOption = /* GraphQL */ `
  mutation DeleteVotingOption(
    $input: DeleteVotingOptionInput!
    $condition: ModelVotingOptionConditionInput
  ) {
    deleteVotingOption(input: $input, condition: $condition) {
      id
      type
      value
      name
      email
      votingEventID
      votingEvent {
        id
        topic
        description
        deadline
        hideResults
        createdAt
        updatedAt
        __typename
      }
      votes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVote = /* GraphQL */ `
  mutation CreateVote(
    $input: CreateVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    createVote(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      votingOptionID
      votingOption {
        id
        type
        value
        name
        email
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVote = /* GraphQL */ `
  mutation UpdateVote(
    $input: UpdateVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    updateVote(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      votingOptionID
      votingOption {
        id
        type
        value
        name
        email
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVote = /* GraphQL */ `
  mutation DeleteVote(
    $input: DeleteVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    deleteVote(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      votingOptionID
      votingOption {
        id
        type
        value
        name
        email
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
