// src/api/api.jsx
import { generateClient } from "aws-amplify/api";
import { listUsers, getUser, listVotingEvents, listVoteRecords, optionsByVotingEvent, listTasks, getTask, listEvents } from "../graphql/queries";
import { createUser, updateUser, createVotingEvent, createVotingOption, updateVotingEvent, deleteVotingEvent, createVote, createVoteRecord, deleteVotingOption, createTask, updateTask, deleteTask, createEvent as createEventMutation, deleteEvent as deleteEventMutation, createPayment, deletePayment } from '../graphql/mutations';
import { getUrl, list, uploadData } from 'aws-amplify/storage';
import { CognitoIdentityProviderClient, AdminCreateUserCommand, AdminAddUserToGroupCommand, AdminListGroupsForUserCommand, ListUsersInGroupCommand, AdminRemoveUserFromGroupCommand } from "@aws-sdk/client-cognito-identity-provider";
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import { cache, isCacheValid, saveToLocalStorage, loadFromLocalStorage, clearCache } from './../cache';
import { userGroupsPrecedenceMap } from './../constants'
import { PaymentType, PaymentMethod } from '../models'; // Adjust the path based on the generated folder


const client = generateClient();

const createCognitoClient = async () => {
  const session = await fetchAuthSession();
  return new CognitoIdentityProviderClient({
    region: "us-west-2",
    credentials: {
      accessKeyId: session.credentials.accessKeyId,
      secretAccessKey: session.credentials.secretAccessKey,
      sessionToken: session.credentials.sessionToken
    }
  });
};

const createSESClient = async () => {
  const session = await fetchAuthSession();
  return new SESClient({
    region: "us-west-2",
    credentials: {
      accessKeyId: session.credentials.accessKeyId,
      secretAccessKey: session.credentials.secretAccessKey,
      sessionToken: session.credentials.sessionToken
    }
  });
};

export const fetchMembers = async () => {
  const cachedMembers = loadFromLocalStorage('members');
  if (cachedMembers) {
    console.log("Members from local storage...");
    return cachedMembers;
  }

  try {
    const apiData = await client.graphql({ query: listUsers });
    const users = apiData.data.listUsers.items;

    const usersWithPics = await Promise.all(users.map(async (user) => {
      try {
        const result = await list({ prefix: `profilePic/${user.email}/pic.jpg` });
        const profilePictureKey = result.items[0]?.key;
        const profilePicUrl = profilePictureKey ? await getUrl({ key: profilePictureKey }) : 'assets/img/avatar-placeholder.jpg';
        return { ...user, profilePic: profilePicUrl.url || profilePicUrl };
      } catch (error) {
        console.error('Error fetching profile picture:', error);
        return { ...user, profilePic: 'assets/img/avatar-placeholder.jpg' };
      }
    }));

    cache.members = usersWithPics;
    cache.timestamps.members = Date.now();
    saveToLocalStorage('members', usersWithPics);
    return usersWithPics;
  } catch (err) {
    console.error('Error fetching users:', err);
    return [];
  }
};

export const addMemberToCognito = async (newMember) => {
  const { email, firstName, lastName, phoneNumber } = newMember;
  const temporaryPassword = generateTemporaryPassword();

  try {
    const client = await createCognitoClient();

    const command = new AdminCreateUserCommand({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: email,
      TemporaryPassword: temporaryPassword,
      UserAttributes: [
        { Name: "email", Value: email },
        { Name: "email_verified", Value: "true" },
        { Name: "given_name", Value: firstName },
        { Name: "family_name", Value: lastName },
        { Name: "phone_number", Value: phoneNumber },
        { Name: "name", Value: `${firstName} ${lastName}` }
      ],
      DesiredDeliveryMediums: ["EMAIL"],
      ForceAliasCreation: false,
    });

    const response = await client.send(command);
    clearCache('members');
    return response.User?.Username; // Return the Cognito username (sub)
  } catch (error) {
    console.error('Error adding new member:', error);
    alert("Adding member failed. We will investigate and resolve this shortly.");
  }
};

export const listUsersInGroup = async (groupName) => {
  try {
    const client = await createCognitoClient();

    const command = new ListUsersInGroupCommand({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      GroupName: groupName
    });

    const response = await client.send(command);
    return response.Users.map(user => {
      const userAttributes = user.Attributes.reduce((acc, attr) => {
        acc[attr.Name] = attr.Value;
        return acc;
      }, {});
      return {
        id: user.Username,
        firstName: userAttributes.name,
        lastName: userAttributes.family_name,
        email: userAttributes.email,
        phoneNumber: userAttributes.phone_number
      };
    });
  } catch (error) {
    console.error('Error listing users in group:', error);
    return [];
  }
};

export const registerGuest = async (guest) => {
  try {
    const client = await createCognitoClient();

    // Add user to 'members' group
    const addToMembersGroupCommand = new AdminAddUserToGroupCommand({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: guest.id,
      GroupName: 'members',
    });
    await client.send(addToMembersGroupCommand);

    // Remove user from 'guests' group
    const removeFromGuestsGroupCommand = new AdminRemoveUserFromGroupCommand({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: guest.id,
      GroupName: 'guests',
    });
    await client.send(removeFromGuestsGroupCommand);

    // Add user to the database
    await addMemberToDB(guest.id, {
      firstName: guest.firstName,
      lastName: guest.lastName,
      email: guest.email,
      phoneNumber: guest.phoneNumber
    });

    return true;
  } catch (error) {
    console.error('Error registering guest:', error);
    throw error;
  }
};

export const addMemberToDB = async (userId, newMember) => {
  try {
    await client.graphql({
      query: createUser,
      variables: {
        input: {
          id: userId,
          firstName: newMember.firstName,
          lastName: newMember.lastName,
          email: newMember.email,
          phoneNumber: newMember.phoneNumber,
          gender: 'MALE',
          country: "Please Update",
          city: "Please Update",
          spokenLanguages: [],
          profession: "Please Update",
          membershipStatus: 'Active'
        }
      }
    });
    clearCache('members');
  } catch (error) {
    console.error('Error adding member to DB:', error);
  }
};

const generateTemporaryPassword = () => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const length = 8;

  const getRandomChar = (set) => set[Math.floor(Math.random() * set.length)];

  let password = [
    getRandomChar("abcdefghijklmnopqrstuvwxyz"),
    getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"),
    getRandomChar("0123456789"),
  ];

  for (let i = password.length; i < length; i++) {
    password.push(getRandomChar(chars));
  }

  return password.sort(() => Math.random() - 0.5).join('');
};

export const loadProfilePicture = async (email) => {
  if (cache.profilePicture[email] && isCacheValid(cache.timestamps.profilePicture[email])) {
    return cache.profilePicture[email];
  }

  try {
    const result = await list({
      prefix: `profilePic/${email}/pic.jpg`
    });
    if (result.items.length > 0) {
      const profilePictureKey = result.items[0].key;
      const profilePicFromStorage = await getUrl({
        key: profilePictureKey,
      });
      cache.profilePicture[email] = profilePicFromStorage.url;
      cache.timestamps.profilePicture[email] = Date.now();
      saveToLocalStorage(`profilePicture_${email}`, profilePicFromStorage.url);
      return profilePicFromStorage.url;
    } else {
      cache.profilePicture[email] = 'assets/img/avatar-placeholder.jpg';
      cache.timestamps.profilePicture[email] = Date.now();
      saveToLocalStorage(`profilePicture_${email}`, 'assets/img/avatar-placeholder.jpg');
      return 'assets/img/avatar-placeholder.jpg';
    }
  } catch (error) {
    console.error('Error reading/loading profile picture:', error);
    return 'assets/img/avatar-placeholder.jpg';
  }
};

export const uploadProfilePicture = async (email, file) => {
  try {
    const result = await uploadData({
      key: `profilePic/${email}/pic.jpg`,
      data: file,
      acl: 'public-read'
    }).result;
    const newProfilePic = await getUrl({
      key: `profilePic/${email}/pic.jpg`,
    });
    cache.profilePicture[email] = newProfilePic.url;
    cache.timestamps.profilePicture[email] = Date.now();
    saveToLocalStorage(`profilePicture_${email}`, newProfilePic.url);
    return newProfilePic.url;
  } catch (error) {
    console.log('Error uploading profile picture:', error);
    throw error;
  }
};

export const fetchProfilePicture = async (email) => {
  if (cache.profilePicture[email] && isCacheValid(cache.timestamps.profilePicture[email])) {
    return cache.profilePicture[email];
  }

  try {
    const result = await list({
      prefix: `profilePic/${email}/pic.jpg`
    });
    if (result.items.length > 0) {
      const profilePictureKey = result.items[0].key;
      const profilePicFromStorage = await getUrl({
        key: profilePictureKey,
      });
      cache.profilePicture[email] = profilePicFromStorage.url;
      cache.timestamps.profilePicture[email] = Date.now();
      saveToLocalStorage(`profilePicture_${email}`, profilePicFromStorage.url);
      return profilePicFromStorage.url;
    } else {
      cache.profilePicture[email] = 'assets/img/avatar-placeholder.jpg';
      cache.timestamps.profilePicture[email] = Date.now();
      saveToLocalStorage(`profilePicture_${email}`, 'assets/img/avatar-placeholder.jpg');
      return 'assets/img/avatar-placeholder.jpg';
    }
  } catch (error) {
    console.error('Error fetching profile picture:', error);
    return 'assets/img/avatar-placeholder.jpg';
  }
};

export const getMemberData = async (userId) => {
  if (cache.memberData[userId] && isCacheValid(cache.timestamps.memberData[userId])) {
    return cache.memberData[userId];
  }

  try {
    const { data } = await client.graphql({
      query: getUser,
      variables: { id: userId }
    });
    const memberData = data.getUser;
    cache.memberData[userId] = memberData;
    cache.timestamps.memberData[userId] = Date.now();
    saveToLocalStorage(`memberData_${userId}`, memberData);
    return memberData;
  } catch (error) {
    console.log('Error reading member details:', error);
    throw error;
  }
};

export const updateMemberData = async (userId, formValues) => {
  try {
    const { data } = await client.graphql({
      query: updateUser,
      variables: {
        input: {
          id: userId,
          ...formValues
        }
      }
    });
    cache.memberData[userId] = data.updateUser;
    cache.timestamps.memberData[userId] = Date.now();
    saveToLocalStorage(`memberData_${userId}`, data.updateUser);
    return data.updateUser;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

const sendEmailNotification = async (email, groupName) => {
  const client = await createSESClient();

  const params = {
    Destination: {
      ToAddresses: [email],
    },
    Message: {
      Body: {
        Text: {
          Data: `You have been assigned to the group: ${groupName}.`,
        },
      },
      Subject: {
        Data: 'Felsi Selam Role Assignment Notification',
      },
    },
    Source: process.env.REACT_APP_SOURCE_EMAIL,
  };

  try {
    const command = new SendEmailCommand(params);
    await client.send(command);
    console.log(`Notification email sent to ${email}`);
  } catch (error) {
    console.error('Error sending email notification:', error);
  }
};

export const assignUserToGroup = async (username, groupName, notify = false) => {
  try {
    const client = await createCognitoClient();

    const command = new AdminAddUserToGroupCommand({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: username,
      GroupName: groupName
    });

    await client.send(command);

    if (notify) {
      await sendEmailNotification(username, groupName);
    }
    clearCache('currentUserGroups', username);
  } catch (error) {
    console.error('Error assigning user to group:', error);
    throw error;
  }
};

export const sendTaskNotification = async (email, taskTitle, dueDate) => {
  const client = await createSESClient();
  const sourceEmail = process.env.REACT_APP_SOURCE_EMAIL;

  if (!sourceEmail) {
    console.error('Source email is not set or not verified.');
    return;
  }

  const params = {
    Destination: {
      ToAddresses: [email],
    },
    Message: {
      Body: {
        Text: {
          Data: `You have been assigned a new task: "${taskTitle}". It is due on ${new Date(dueDate).toLocaleString()}.`,
        },
      },
      Subject: {
        Data: 'New Task Assigned',
      },
    },
    Source: sourceEmail,
  };

  try {
    const command = new SendEmailCommand(params);
    await client.send(command);
    console.log(`Task notification email sent to ${email}`);
  } catch (error) {
    console.error('Error sending task notification email:', error);
  }
};

export const sendRegistrationNotification = async (email, firstName, lastName) => {
  const client = await createSESClient();
  const sourceEmail = process.env.REACT_APP_SOURCE_EMAIL; // Ensure this is set correctly

  if (!sourceEmail) {
    console.error('Source email is not set or not verified.');
    return;
  }

  const params = {
    Destination: {
      ToAddresses: [email],
    },
    Message: {
      Body: {
        Text: {
          Data: `Dear ${firstName} ${lastName},\n\nYou have been successfully registered as a member of Felsi Selam. \n\nPlease log in again to your account and update your profile with your information. \n\nThank you!\nFelsi Selam Recruitment Team \nhttps://felsiselam.org/dashboard`,
        },
      },
      Subject: {
        Data: 'Welcome to Felsi Selam Online Account!',
      },
    },
    Source: sourceEmail,
  };

  try {
    const command = new SendEmailCommand(params);
    await client.send(command);
    console.log(`Registration notification email sent to ${email}`);
  } catch (error) {
    console.error('Error sending registration notification email:', error);
  }
};

export const getUserGroups = async (username) => {
  if (cache.currentUserGroups[username] && isCacheValid(cache.timestamps.currentUserGroups[username])) {
    return cache.currentUserGroups[username];
  }

  try {
    const client = await createCognitoClient();

    const command = new AdminListGroupsForUserCommand({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: username
    });

    const response = await client.send(command);
    const groups = response.Groups.map(group => group.GroupName);
    cache.currentUserGroups[username] = groups;
    cache.timestamps.currentUserGroups[username] = Date.now();
    saveToLocalStorage(`currentUserGroups_${username}`, groups);
    return groups;
  } catch (error) {
    console.error('Error fetching user groups:', error);
    return [];
  }
};

export const getCurrentUserGroups = async () => {
  try {
    const session = await fetchAuthSession();
    const cognitoGroups = session.tokens.idToken.payload["cognito:groups"];
    const groups = cognitoGroups.map(groupName => ({
      name: groupName,
      precedence: userGroupsPrecedenceMap[groupName]
    }));
    return groups;
  } catch (error) {
    console.error('Error fetching current user groups:', error);
    return [];
  }
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    // Check cache first
    const cachedUser = cache.currentUser || loadFromLocalStorage('currentUser');
    if (cachedUser && isCacheValid(cache.timestamps.currentUser)) {
      return cachedUser;
    }

    // Fetch current user from API
    const user = await getCurrentUser();

    // Update cache and local storage
    cache.currentUser = user;
    cache.timestamps.currentUser = Date.now();
    saveToLocalStorage('currentUser', user);

    return user;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};

export const fetchVotingEvents = async (loadExpiredEvents) => {
  try {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const client = generateClient();
    const result = loadExpiredEvents ? await client.graphql({
      query: listVotingEvents
    }) : await client.graphql({
      query: listVotingEvents,
      variables: {
        filter: {
          deadline: {
            ge: yesterday.toISOString(),
          },
        },
      },
    });

    const events = result.data.listVotingEvents.items;

    const eventsWithDetails = await Promise.all(events.map(async (event) => {
      const optionsResult = await client.graphql({
        query: /* GraphQL */ `
          query GetVotingEvent($id: ID!) {
            getVotingEvent(id: $id) {
              id
              topic
              description
              deadline
              hideResults
              options {
                items {
                  id
                  type
                  value
                  name
                  email
                  votes {
                    items {
                      id
                      userId
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { id: event.id },
      });
      return {
        ...event,
        options: optionsResult.data.getVotingEvent.options.items || [],
      };
    }));

    return eventsWithDetails;
  } catch (error) {
    console.log("Error while fetching vote results " + error);
    return [];
  }
};

export const createVotingEventWithOptions = async ({ topic, description, deadline, hideResults, options }) => {
  const client = generateClient();
  const input = { topic, description, deadline, hideResults };

  const data = await client.graphql({
    query: createVotingEvent,
    variables: { input }
  });

  const event = data.data.createVotingEvent;

  for (let option of options) {
    const optionInput = {
      type: option.type,
      value: option.value,
      name: option.name,
      email: option.email,
      votingEventID: event.id
    };
    await client.graphql({
      query: createVotingOption,
      variables: { input: optionInput }
    });
  }
};

export const createVoteOption = async (optionInput) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: createVotingOption,
      variables: {
        input: optionInput
      }
    });
  } catch (error) {
    console.error("Error creating vote option:", error);
    throw error;
  }
}

export const updateVoteEvent = async (updatedEvent) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: updateVotingEvent,
      variables: {
        input: {
          id: updatedEvent.id,
          topic: updatedEvent.topic,
          description: updatedEvent.description,
          deadline: updatedEvent.deadline,
          hideResults: updatedEvent.hideResults
        }
      }
    });
    return true;
  } catch (error) {
    console.error("Update Voting Event Failed.", error);
    return false;
  }
};

export const deleteVoteEvent = async (eventId) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: deleteVotingEvent,
      variables: {
        input: {
          id: eventId
        }
      }
    });
    return true;
  } catch (error) {
    console.error("Delete Voting Event Failed.", error);
    return false;
  }
};

export const deleteVoteOption = async (optionId) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: deleteVotingOption,
      variables: {
        input: {
          id: optionId
        }
      }
    });
    return true;
  } catch (error) {
    console.error("Delete Voting Option Failed.", error);
    return false;
  }
};

export const checkIfUserHasVoted = async (eventId) => {
  const client = generateClient();
  const currentUser = await getCurrentAuthenticatedUser();
  const userId = currentUser?.userId;

  const result = await client.graphql({
    query: listVoteRecords,
    variables: {
      filter: {
        voterID: {
          eq: userId,
        },
        votingEventID: {
          eq: eventId,
        },
      },
    },
  });

  return result.data.listVoteRecords.items.length > 0;
};

export const submitVote = async (userId, selectedOptionId) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: createVote,
      variables: {
        input: {
          userId,
          votingOptionID: selectedOptionId,
        }
      }
    });
  } catch (error) {
    console.error("Error creating vote:", error);
    throw error;
  }
};

export const addVoteRecord = async (userId, eventId) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: createVoteRecord,
      variables: {
        input: {
          voterID: userId,
          votingEventID: eventId,
        },
      },
    });
  } catch (error) {
    console.error("Error creating vote record:", error);
    throw error;
  }
};

export const deleteVoteEventWithOptionsAndVotes = async (eventId) => {
  const client = generateClient();
  try {
    const eventOptionsResult = await client.graphql({
      query: optionsByVotingEvent,
      variables: {
        votingEventID: eventId,
      },
    });

    const options = eventOptionsResult.data.optionsByVotingEvent.items;

    for (const option of options) {
      // const votesResult = await client.graphql({
      //   query: votesByVotingOption,
      //   variables: {
      //     votingOptionID: option.id,
      //   },
      // });

      // const votes = votesResult.data.votesByVotingOption.items;
      // for (const vote of votes) {
      //   await client.graphql({
      //     query: deleteVote,
      //     variables: {
      //       input: {
      //         id: vote.id,
      //       },
      //     },
      //   });
      // }

      await client.graphql({
        query: deleteVotingOption,
        variables: {
          input: {
            id: option.id,
          },
        },
      });
    }

    await client.graphql({
      query: deleteVotingEvent,
      variables: {
        input: {
          id: eventId,
        },
      },
    });

    return true;
  } catch (error) {
    console.error("Delete Voting Event and its associated data failed.", error);
    return false;
  }
};

export const createTaskForMember = async (taskData) => {
  const client = generateClient();
  const { title, description, dueDate, assignedBy, userID } = taskData;
  try {
    await client.graphql({
      query: createTask,
      variables: {
        input: {
          title: title,
          description: description,
          assignedBy: assignedBy,
          dueDate: dueDate,
          status: 'ASSIGNED', // Ensure the status is correct according to your schema
          userID: userID
        }
      }
    });
  } catch (error) {
    throw error;
  }
};

export const fetchTasksForUser = async (userId) => {
  const client = generateClient();
  try {
    const result = await client.graphql({
      query: listTasks,
      variables: {
        filter: {
          userID: { eq: userId },
        },
      },
    });
    return result.data.listTasks.items;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    throw error;
  }
};

export const updateTaskStatus = async (taskId, status) => {
  const client = generateClient();
  try {
    const { data } = await client.graphql({
      query: getTask,
      variables: { id: taskId },
    });

    const task = data.getTask;

    await client.graphql({
      query: updateTask,
      variables: {
        input: {
          id: task.id,
          title: task.title,
          description: task.description,
          assignedBy: task.assignedBy,
          dueDate: task.dueDate,
          status,
          userID: task.userID,
        },
      },
    });
  } catch (error) {
    console.error("Error updating task status:", error);
    throw error;
  }
};

export const deleteTaskById = async (taskId) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: deleteTask,
      variables: {
        input: {
          id: taskId,
        },
      },
    });
    return true;
  } catch (error) {
    console.error("Error deleting task:", error);
    return false;
  }
};

export const fetchEvents = async () => {
  try {
    const { data } = await client.graphql({ query: listEvents });
    return data.listEvents.items;
  } catch (error) {
    console.error('Error fetching events:', error);
    return [];
  }
};

export const createEvent = async (eventData) => {
  try {
    const { data } = await client.graphql({
      query: createEventMutation,
      variables: {
        input: eventData,
      },
    });
    return data.createEvent;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const deleteEvent = async (id) => {
  try {
    await client.graphql({
      query: deleteEventMutation,
      variables: {
        input: { id },
      },
    });
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};

export const fetchPaymentsForMember = async (userId) => {
  try {
    const client = generateClient();
    const { data } = await client.graphql({
      query: /* GraphQL */ `
        query PaymentsByUser($userId: ID!) {
          paymentsByUser(userId: $userId) {
            items {
              id
              amountInUSD
              paymentDate
              memo
              status
              paymentType
              paymentMethod
              createdAt
            }
          }
        }
      `,
      variables: { userId },
    });
    return data.paymentsByUser.items;
  } catch (error) {
    console.error("Error fetching payment history:", error);
    return [];
  }
};

export const savePaymentsForMember = async (user, payments) => {
  const client = generateClient();

  // Helper function to map payment types
  const getPaymentType = (type) => {
    const mapping = {
      Payment: PaymentType.PAYMENT,
      Pledge: PaymentType.PLEDGE,
    };
    return mapping[type] || PaymentType.PAYMENT;
  };
  // Helper function to map payment methods
  const getPaymentMethod = (method) => {
    const mapping = {
      Zelle: PaymentMethod.ZELLE,
      Paypal: PaymentMethod.PAYPAL,
      Wire: PaymentMethod.WIRE,
      Cash: PaymentMethod.CASH,
      Check: PaymentMethod.CHECK,
    };
    return mapping[method] || PaymentMethod.CASH;
  };

  for (const payment of payments) {
    try {
      // Attempt to create the payment
      await client.graphql({
        query: createPayment,
        variables: {
          input: {
            id: payment.id,
            amountInUSD: payment.Amount,
            paymentType: getPaymentType(payment.Type), 
            paymentMethod: getPaymentMethod('Paypal'), // Assuming PAYPAL is a default method
            userId: user.id,
            paymentDate: payment.Date,
            memo: payment.Memo || "",
            status: payment.Status || "Pending",
          },
        },
      });
      console.log(`Payment with ID ${payment.id} saved successfully.`);
    } catch (error) {
      if (error.data && error.data.createPayment) {
        console.warn(
          `Payment created but user is null for userId: ${error.data.createPayment.userId}`
        );
      } else if (
        error.errors &&
        error.errors[0]?.message?.includes("The conditional request failed")
      ) {
        console.log(`Payment with ID ${payment.id} already exists. Skipping.`);
        continue; // Skip this payment
      } else {
        console.error(`Error saving payment with ID ${payment.id}:`, error);
        throw error;
      }
    }
  }
};


export const deletePaymentById = async (paymentId) => {
  const client = generateClient();
  try {
    await client.graphql({
      query: deletePayment, // Replace with your actual GraphQL mutation for deleting a payment
      variables: {
        input: {
          id: paymentId,
        },
      },
    });
    console.log("Payment deleted successfully.");
  } catch (error) {
    console.error("Error deleting payment:", error);
    throw error;
  }
};
