/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      description
      assignedBy
      dueDate
      status
      userID
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        assignedBy
        dueDate
        status
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByUserID = /* GraphQL */ `
  query TasksByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        assignedBy
        dueDate
        status
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVoteRecord = /* GraphQL */ `
  query GetVoteRecord($id: ID!) {
    getVoteRecord(id: $id) {
      id
      voterID
      votingEventID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVoteRecords = /* GraphQL */ `
  query ListVoteRecords(
    $filter: ModelVoteRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVoteRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        voterID
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      country
      paymentHistory {
        nextToken
        __typename
      }
      city
      spokenLanguages
      profession
      membershipStatus
      votes {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      amountInUSD
      paymentType
      paymentMethod
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentDate
      memo
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountInUSD
        paymentType
        paymentMethod
        userId
        paymentDate
        memo
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentsByUser = /* GraphQL */ `
  query PaymentsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amountInUSD
        paymentType
        paymentMethod
        userId
        paymentDate
        memo
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      date
      zoomLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        date
        zoomLink
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVotingEvent = /* GraphQL */ `
  query GetVotingEvent($id: ID!) {
    getVotingEvent(id: $id) {
      id
      topic
      description
      deadline
      hideResults
      options {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVotingEvents = /* GraphQL */ `
  query ListVotingEvents(
    $filter: ModelVotingEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotingEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topic
        description
        deadline
        hideResults
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVotingOption = /* GraphQL */ `
  query GetVotingOption($id: ID!) {
    getVotingOption(id: $id) {
      id
      type
      value
      name
      email
      votingEventID
      votingEvent {
        id
        topic
        description
        deadline
        hideResults
        createdAt
        updatedAt
        __typename
      }
      votes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVotingOptions = /* GraphQL */ `
  query ListVotingOptions(
    $filter: ModelVotingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotingOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        value
        name
        email
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const optionsByVotingEvent = /* GraphQL */ `
  query OptionsByVotingEvent(
    $votingEventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVotingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    optionsByVotingEvent(
      votingEventID: $votingEventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        value
        name
        email
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVote = /* GraphQL */ `
  query GetVote($id: ID!) {
    getVote(id: $id) {
      id
      userId
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        gender
        country
        city
        spokenLanguages
        profession
        membershipStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      votingOptionID
      votingOption {
        id
        type
        value
        name
        email
        votingEventID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        votingOptionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const votesByUser = /* GraphQL */ `
  query VotesByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        votingOptionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const votesByVotingOption = /* GraphQL */ `
  query VotesByVotingOption(
    $votingOptionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByVotingOption(
      votingOptionID: $votingOptionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        votingOptionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
