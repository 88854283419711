// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TasKStatus = {
  "ASSIGNED": "ASSIGNED",
  "DECLINED": "DECLINED",
  "ACCEPTED": "ACCEPTED",
  "DONE": "DONE"
};

const Gender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const MembershipStatus = {
  "ACTIVE": "Active",
  "SUPPORTER": "Supporter",
  "OBSERVER": "Observer"
};

const PaymentType = {
  "PAYMENT": "Payment",
  "PLEDGE": "Pledge"
};

const PaymentMethod = {
  "ZELLE": "Zelle",
  "PAYPAL": "Paypal",
  "WIRE": "Wire",
  "CASH": "Cash",
  "CHECK": "Check"
};

const VotingOptionType = {
  "STATEMENT": "STATEMENT",
  "PERSON": "PERSON"
};

const { Task, VoteRecord, User, Payment, Event, VotingEvent, VotingOption, Vote } = initSchema(schema);

export {
  Task,
  VoteRecord,
  User,
  Payment,
  Event,
  VotingEvent,
  VotingOption,
  Vote,
  TasKStatus,
  Gender,
  MembershipStatus,
  PaymentType,
  PaymentMethod,
  VotingOptionType
};