import React, { useState, useEffect } from "react";
import { Card, Dropdown, DropdownButton, Form, Row, Col, Pagination } from "react-bootstrap";
import { IoMdRefresh } from "react-icons/io";
import { FaCog } from "react-icons/fa";
import MembersTable from "./MembersTable";
import AddMemberForm from "./AddMemberForm";
import { fetchMembers, getCurrentUserGroups } from "./../../api/api";
import { userGroups } from "../../constants";
import { clearCache } from "../../cache";
import UploadPaymentModal from "./UploadPaymentModal";

function MemberList() {
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);

  const loadMembers = async () => {
    const membersData = await fetchMembers();
    setMembers(membersData);
  };

  const fetchCurrentUserGroups = async () => {
    const currentUserGroups = await getCurrentUserGroups();
    const highestPrecedence = Math.min(...currentUserGroups.map(group => userGroups.find(g => g.name === group.name)?.precedence || Infinity));
    const filteredGroups = userGroups.filter(group => group.precedence >= highestPrecedence);
    setAvailableGroups(filteredGroups);
  };

  useEffect(() => {
    loadMembers();
    fetchCurrentUserGroups();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleRefresh = () => {
    clearCache("members");
    loadMembers();
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleUploadModalShow = () => setShowUploadModal(true);
  const handleUploadModalClose = () => setShowUploadModal(false);

  const filteredMembers = members.filter(
    (member) =>
      member.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.lastName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const membersPerPage = 20;
  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);

  const totalPages = Math.ceil(filteredMembers.length / membersPerPage);

  return (
    <Card className="mt-4">
      <Card.Header>
        <Row>
          <Col>
            Members <IoMdRefresh onClick={handleRefresh} style={{ cursor: "pointer" }} />
          </Col>
          <Col className="text-end">
            <DropdownButton
              id="dropdown-basic-button"
              title={
                <>
                  <FaCog style={{ marginRight: "5px" }} />
                  Manage Members
                </>
              }
              variant="primary"
              size="sm"
            >
              <Dropdown.Item onClick={handleShowModal}>Add Member</Dropdown.Item>
              <Dropdown.Item onClick={handleUploadModalShow}>Upload Payment History</Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Control
              type="text"
              placeholder="Search by name"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <MembersTable
          members={currentMembers}
          refreshMembers={loadMembers}
          availableGroups={availableGroups}
          currentPage={currentPage}
          membersPerPage={membersPerPage}
        />
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Card.Body>

      <AddMemberForm show={showModal} handleClose={handleCloseModal} fetchMembers={loadMembers} />

      <UploadPaymentModal
        show={showUploadModal}
        handleClose={handleUploadModalClose}
        members={members}
      />
    </Card>
  );
}

export default MemberList;