import React, { useState } from "react";
import { Modal, Button, Form, ProgressBar, Alert } from "react-bootstrap";
import Papa from "papaparse";
import { savePaymentsForMember } from "./../../api/api";

function UploadPaymentModal({ show, handleClose, members }) {
  const [csvFiles, setCsvFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState(null); // Message for success or error

  const handleFileChange = (e) => {
    setCsvFiles(Array.from(e.target.files));
    setUploadMessage(null); // Reset message when files change
  };

  const handleParseFiles = async () => {
    setProcessing(true);
    setProgress(0);
    setUploadMessage(null);

    const totalFiles = csvFiles.length;
    let successCount = 0;
    let errorCount = 0;

    for (let i = 0; i < totalFiles; i++) {
      const file = csvFiles[i];
      try {
        const { email, payments } = await processFile(file);

        const member = members.find((m) => m.email === email);

        if (!member) {
          console.error(`Member not found for email: ${email}`);
          errorCount++;
          continue;
        }

        await savePaymentsForMember(member, payments);
        console.log(`Payments saved for ${email}`);
        successCount++;
      } catch (error) {
        console.error(`Error processing file "${file.name}":`, error);
        errorCount++;
      }

      setProgress(Math.round(((i + 1) / totalFiles) * 100));
    }

    setProcessing(false);

    // Display appropriate message
    if (errorCount === 0) {
      setUploadMessage({ type: "success", text: "Payment history uploaded successfully!" });
    } else {
      setUploadMessage({
        type: "danger",
        text: `Completed with errors. ${successCount} files processed successfully, ${errorCount} files failed.`,
      });
    }
  };

  const processFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          try {
            const payments = result.data.map(parseRow);
            const email = result.data[0]?.Email; // Extract email from the first row
            if (!email) {
              throw new Error("Email column is missing or empty in the CSV file.");
            }
            resolve({ email, payments });
          } catch (error) {
            reject(error);
          }
        },
        error: (error) => reject(error),
      });
    });
  };

  const parseRow = (row) => ({
    id: row.No || null, // Use No. column as ID
    Date: formatDate(row.Date), // Convert MM/DD/YYYY to YYYY-MM-DD
    Type: row.Type,
    Memo: row.Memo,
    Amount: parseFloat(row.Amount?.replace(/[^0-9.-]+/g, "")), // Remove non-numeric characters
    Status: row.Status,
  });

  const formatDate = (date) => {
    const dateParts = date.split("/");
    if (dateParts.length !== 3) return null;
    return `${dateParts[2]}-${dateParts[0].padStart(2, "0")}-${dateParts[1].padStart(2, "0")}`;
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upload Payment History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please upload one or more CSV files containing payment history. Each file should follow
          this format:
        </p>
        <pre>
          <code>
            Date,Type,No.,Customer,Memo,Email,Amount,Status{`\n`}
            08/22/2023,Cash,123,FirstName LastName,memo about the payment,user@example.com,-45.09,applied{`\n`}
            07/18/2023,Online,124,FirstName LastName,memo about the payment,user@example.com,-62.29,applied
          </code>
        </pre>
        <Form.Group>
          <Form.Label>Select CSV Files</Form.Label>
          <Form.Control type="file" multiple accept=".csv" onChange={handleFileChange} />
        </Form.Group>
        {processing && (
          <div className="mt-3">
            <ProgressBar now={progress} label={`${progress}%`} />
          </div>
        )}
        {uploadMessage && (
          <Alert className="mt-3" variant={uploadMessage.type}>
            {uploadMessage.text}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleParseFiles}
          disabled={csvFiles.length === 0 || processing}
        >
          {processing ? "Processing..." : "Process Files"}
        </Button>
        <Button variant="secondary" onClick={handleClose} disabled={processing}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadPaymentModal;
