import React, { useState, useEffect } from "react";
import { Card, Table, Modal, Button } from "react-bootstrap";
import { fetchPaymentsForMember, getCurrentAuthenticatedUser, getMemberData } from "./../../api/api";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";

function PaymentsComponent() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    loadPayments();
  }, []);

  useEffect(() => {
    if (showReceiptModal && selectedPayment) {
      generateBarcode(selectedPayment.paymentDate, selectedPayment.id);
    }
  }, [showReceiptModal, selectedPayment]);

  const loadPayments = async () => {
    try {
      const user = await getCurrentAuthenticatedUser();
      const member = await getMemberData(user.userId);
      setCurrentUser(member);
      const paymentsData = await fetchPaymentsForMember(user.userId);
      setPayments(paymentsData);
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewReceipt = (payment) => {
    setSelectedPayment(payment);
    setShowReceiptModal(true);
  };

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false);
    setSelectedPayment(null);
  };

  const generateBarcode = (date, id) => {
    const barcodeElement = document.getElementById("barcode");
    if (barcodeElement) {
      const formattedDate = formatDateForBarcode(date); // Format date to mm-dd-yyyy
      const barcodeValue = `${formattedDate}-${id}`;
      const modalWidth = document.getElementById("receipt-content").offsetWidth;
      const barcodeWidth = modalWidth * 0.2; // 50% of modal width
      const barcodeHeight = barcodeWidth / 2;

      JsBarcode(barcodeElement, barcodeValue, {
        format: "CODE128",
        width: barcodeWidth / 100,
        height: barcodeHeight,
        displayValue: true,
        fontSize: 12,
        textMargin: 5,
      });
    }
  };

  const formatDateForBarcode = (date) => {
    if (!date) return "N/A";
    const [year, month, day] = date.split("-");
    return `${month}-${day}-${year}`;
  };

  const handleDownloadImage = async () => {
    const receiptElement = document.getElementById("receipt-content");
    const canvas = await html2canvas(receiptElement);
    const image = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = image;
    link.download = `receipt-${selectedPayment.id}.png`;
    link.click();
  };

  const handleDownloadPDF = async () => {
    const receiptElement = document.getElementById("receipt-content");
    const canvas = await html2canvas(receiptElement);
    const image = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgWidth = 190;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(image, "PNG", 10, 10, imgWidth, imgHeight);
    pdf.save(`receipt-${selectedPayment.id}.pdf`);
  };

  return (
    <Card className="mt-4">
      <Card.Header>Membership Payment History</Card.Header>
      <Card.Body style={{ maxHeight: "250px", overflowY: "auto" }}>
        {loading ? (
          <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "grey" }}>
            Loading...
          </p>
        ) : payments.length > 0 ? (
          <Table bordered>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount (USD)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.paymentDate || "N/A"}</td>
                  <td>{payment.amountInUSD}</td>
                  <td>
                    <a href="#" style={{ textDecoration: "none", color: "#0d6efd", cursor: "pointer" }}
                      onClick={(e) => { e.preventDefault();
                        handleViewReceipt(payment);
                    }}>
                      View Receipt
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "grey" }}>
            No payment history available.
          </p>
        )}
      </Card.Body>

      {/* Receipt Modal */}
      <Modal show={showReceiptModal} onHide={handleCloseReceiptModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPayment && (
            <div id="receipt-content" style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "5px" }}>
              <div style={{ textAlign: "center" }}>
                <img
                  src="/assets/img/felsiselam_logo.jpg"
                  alt="Felsi Selam Logo"
                  style={{ width: "150px", marginBottom: "20px" }}
                />
                <h3>Payment Receipt | ቅብሊት </h3>
              </div>
              <hr />
              <p><strong>Name | ስም :</strong> {currentUser.firstName} {currentUser.lastName}</p>
              <p><strong>Date | ዕለት :</strong> {selectedPayment.paymentDate}</p>
              <p><strong>Amount | መጠን ገንዘብ :</strong> ${selectedPayment.amountInUSD}</p>
              <p><strong>Payment Type | ዓይነት ክፍሊት :</strong> {selectedPayment.paymentType}</p>
              {/* <p><strong>Payment Method | ኣገባብ ክፍሊት :</strong> {selectedPayment.paymentMethod}</p> */}
              <p><strong>Memo | መለለዪ :</strong> {selectedPayment.memo || "N/A"}</p>
              <p><strong>Status | ደረጃ :</strong> {selectedPayment.status}</p>
              <hr />
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <svg id="barcode"></svg>
              </div>
              <p style={{ textAlign: "center", fontSize: "12px", color: "grey", marginTop: "10px" }}>
                Thank you for your payment. This receipt is generated electronically.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseReceiptModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDownloadImage}>
            Download as Image
          </Button>
          <Button variant="success" onClick={handleDownloadPDF}>
            Download as PDF
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default PaymentsComponent;